import { Component, OnInit } from '@angular/core';


import { UserDataService } from '../../../../services/user-data.service'
import { reduce } from 'rxjs/operators';


@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit {


  //
  charts;
  colors =[
    {
      backgroundColor: ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', 
      '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
      '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
      '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
      '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
      '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
      '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
      '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
      '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
      '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF']
    }
  ];
  

//initializing charts vars
  employee_company_qty_chart;
  nfe_type_chart;
  company_business_chart;
  company_income_chart;
  business_position_chart;
  usage_chart 
  has_company_chart
  certificate_chart
  emit_nf_chart

  constructor(private data: UserDataService) { }

  ngOnInit() {
    this.updateAll();
  }


  updateAll() {
    this.data.userData().subscribe((res: any) => {

      this.charts = res;

      var employee_company_qty_data = [];
      var employee_company_qty_labels = [];

      var nfe_type_data = [];
      var nfe_type_labels = [];

      var company_business_data = [];
      var company_business_labels = [];

      var company_income_data = [];
      var company_income_labels = [];

      var business_position_data = [];
      var business_position_labels = [];

      var has_company_data = [];
      var has_company_labels = [];

      var usage_data = [];
      var usage_labels = [];

      var certificate_data = [];
      var certificate_labels = [];

      var emit_nf_data = [];
      var emit_nf_labels = [];


      for (let i = 0; i < res.employee_company_qty.length; i++) {
        const ele = res.employee_company_qty[i];
        employee_company_qty_data.push(ele.count);
        employee_company_qty_labels.push(ele.res);
      }

      for (let i = 0; i < res.nfe_type.length; i++) {
        const ele = res.nfe_type[i];
        nfe_type_data.push(ele.count);
        nfe_type_labels.push(ele.res);
      }

      for (let i = 0; i < res.company_business.length; i++) {
        const ele = res.company_business[i];
        company_business_data.push(ele.count);
        company_business_labels.push(ele.res);
      }

      for (let i = 0; i < res.company_income.length; i++) {
        const ele = res.company_income[i];
        company_income_data.push(ele.count);
        company_income_labels.push(ele.res);
      }

      for (let i = 0; i < res.business_position.length; i++) {
        const ele = res.business_position[i];
        business_position_data.push(ele.count);
        business_position_labels.push(ele.res);
      }




      // ----       

      res.has_company.map(r => {
        has_company_data.push(r.count);
        has_company_labels.push(r.res);
      })

      res.usage.map(r => {
        usage_data.push(r.count);
        usage_labels.push(r.res);
      })

      res.emit_nf.map(r => {
        emit_nf_data.push(r.count);
        emit_nf_labels.push(r.res);
      })
      res.certificate.map(r => {
        certificate_data.push(r.count);
        certificate_labels.push(r.res);
      })

          




        this.has_company_chart = {
          chartData: [has_company_data],
          backgroundColor: this.colors,
          chartLabels: has_company_labels,
          mode: 'doughnut',
        }

        this.usage_chart = {
          chartData: [usage_data],
          backgroundColor: this.colors,
          chartLabels: usage_labels,
          mode: 'doughnut',
        }
        this.emit_nf_chart = {
          chartData: [emit_nf_data],
          backgroundColor: this.colors,
          chartLabels: emit_nf_labels,
          mode: 'doughnut',
        }
        this.certificate_chart = {
          chartData: [certificate_data],
          backgroundColor: this.colors,
          chartLabels: certificate_labels,
          mode: 'doughnut',
        }

        // ----






        this.employee_company_qty_chart = {
          chartData: [employee_company_qty_data],
          backgroundColor: this.colors,
          chartLabels: employee_company_qty_labels,
          mode: 'doughnut',
        }


          this.nfe_type_chart = {
            chartData: [nfe_type_data],
            backgroundColor: this.colors,
            chartLabels: nfe_type_labels,
            mode: 'doughnut'
          }


          this.company_business_chart = {
            chartData: [company_business_data],
            backgroundColor: this.colors,
            chartLabels: company_business_labels,
            mode: 'doughnut'
          }

          this.company_income_chart = {
            chartData: [company_income_data],
            backgroundColor: this.colors,
            chartLabels: company_income_labels,
            mode: 'doughnut'
          }

          this.business_position_chart = {
            chartData: [business_position_data],
            backgroundColor: this.colors,
            chartLabels: business_position_labels,
            mode: 'doughnut'
          }

    })
  }

}
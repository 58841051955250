import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../../services/account.service';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
	loading = true
	columns = [
		{name: 'Nome', sortingId: 'name', loadingSort: false },
		{name: 'Email', sortingId: 'email', loadingSort: false },
		{name: 'Tipo', sortingId: 'role', loadingSort: false },
	]
	model: any = []
	originalModel: any = []
	constructor(private accountService: AccountService ) { }

	ngOnInit() {
		this.accountService.list().subscribe(res => {
			this.loading = false
			this.model = res
			this.originalModel = res
		})
	}
	searchChanged($event){
		var search = $event.target.value.toLowerCase()
		var searchedArray = this.originalModel.filter(elem => 
			elem.name.toLowerCase().includes(search) || 
			elem.email.toLowerCase().includes(search) || 
			elem.role_name.toLowerCase().includes(search)
			)
		this.model = searchedArray
	}

}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { Colors, ChartsModule } from 'ng2-charts';
import { HelperService } from '../../../../services/helper.service'
import { Options } from 'selenium-webdriver/safari';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {




    doughnutChartOptions = { //pizza
      responsive: true,

      elements: {
        arc: {
          borderColor: '#122D4C',
          borderWidth: '10',
        }
      },
      legend: {
        display: true,
        fullWidth: true,
        position: 'left',
        labels: {
          fontColor: '#FFF'
        }
      },


    };




    doughnutColors:any[] = [
      { backgroundColor: ["#86c7f3", "#ffe199"] },
      { borderColor: ["#AEEBF2", "#FEFFC9"] }];




  doughnutChartLabels: any = ['Assinantes', 'Gratuitos'];
  doughnutChartData: any;
  doughnutChartType: ChartType = 'doughnut';

  doughnutChartColours: any[] = [
    { backgroundColor: ['#F9C352', '#214064'] },
    { borderColor: ["#AEEBF2", "#FEFFC9"] }

  ];


  // doughnutChartOptions: ChartOptions = { options: {
  //   borderWidth: 1
  // }}










  nfChart: any
  userGrowthChart: any
  payingUsersGrowthChart: any

  userChart: any
  cards: any = {}
  userGrowth : any = []

  payingUsersGrowth : any = []


  colors: Array<any> = [
    {
      backgroundColor: '#F9C352'
    },
    {
      backgroundColor: '#D3DDEB'
    },
    {
      backgroundColor: '#AEECD6'
    },
  ]


  // options: any = {
  //   legend: {
  //     position: 'bottom', display: false
  //   },
  //   scales: {
  //     yAxes: [{
  //       ticks: {
  //         display: true,
  //         beginAtZero: true,
  //         callback: function (value, index, values) {
  //           // var helper = new HelperService(null)
  //           return value
  //         }
  //       }
  //     }]
  //   },
  //   tooltips: {
  //     callbacks: {
  //       label: function (tooltipItem, data) {
  //         // var label = data.datasets[tooltipItem.datasetIndex].label || '';
  //         // var helper = new HelperService(null)
  //         // return ' ' + label + ': ' + helper.getFormattedPrice(tooltipItem.yLabel)
  //         return ' ' + tooltipItem.yLabel
  //       }
  //     }
  //   }
  // };



  constructor(private helper: HelperService) { }

  ngOnInit() {
    // this.makeUserscharts([])
    // this.makeNFCharts([['u','as','sas','ff','sds', 'asd', 'asdas'], [2,4,5,6,7,4,5,6]])


  	this.helper.getDashboardCards().subscribe(res => {
      console.log(res);
      this.cards = res

      this.cards.convertion = (this.cards.usuarios_pagantes/this.cards.usuarios_nao_pagantes*100).toFixed(2);

      this.doughnutChartData = [[this.cards.usuarios_pagantes, this.cards.usuarios_nao_pagantes]]
      this.doughnutChartLabels = ['Assinantes', 'Gratuitos']
    })

    

    


  }

  ngAfterViewInit() {
    console.log('ngOnAfterViewInit')
      this.helper.getDashboardUserGrowth().subscribe(res => {
      console.log('getDashboardUserGrowth', res);
      this.userGrowth = res
      this.makeUserGrowthChart();

    })

    // this.helper.getDashboardPayingUsersGrowth().subscribe(res => {
    //   console.log(res);
    //   this.payingUsersGrowth = res
    // this.makePayingUsersGrowthChart();

    // })

  }












 // events
 public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  console.log(event, active);
}

public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  console.log(event, active);
}


















  // makePayingUsersGrowthChart() {
  //   var uGData =[[],[]];

  //     for (let i = 0; i < this.payingUsersGrowth.length; i++) {
  //       uGData[0].push(this.payingUsersGrowth[i].date);
  //       uGData[1].push(this.payingUsersGrowth[i].count);
  //     }

  //     this.payingUsersGrowthChart = {
  //       chartData: [
  //         { data: uGData[1], label: 'Entrada' }
  //       ],
  //       chartLabels: uGData[0],
  //       title: 'Crescimento de Usuários',
  //       mode: "bar"
  //     }
  //   }
  makeUserGrowthChart() {
  var uGData =[[],[],[]];


    this.userGrowth.map(r => {
      var d = r.date.split('-')
      if(d[1].length == 1) {
        d[1] = '0'+d[1]
      }
      uGData[0].push(d[1]+'/'+d[0]);
      uGData[1].push(r.registered);
      uGData[2].push(r.paying);

    })
    this.userGrowthChart = {
      chartData: [
        { data: uGData[1], label: 'Cadastros' },
        { data: uGData[2], label: 'Pagantes' }
      ],
      chartLabels: uGData[0],
      title: 'Usuários',
      mode: "bar"
    }



  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SupportService } from '../../../../services/support/support.service';
import { MessageModel } from '../../../../models/FirebaseModels';

@Component({
	selector: 'app-ticket',
	templateUrl: './ticket.component.html',
	styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {

	loading = true
	subject = ''
	ticket: any = {}
	messages: [MessageModel]
	ticketSelected: any
	constructor(
		private activatedRoute: ActivatedRoute,
		private supportService: SupportService
		) { }

	ngOnInit() {
		this.loading = true
		this.ticket = JSON.parse(this.activatedRoute.snapshot.paramMap.get('ticket'))
		var ticketKey = this.ticket.key;

		this.supportService.getTicket(ticketKey).subscribe(res => {
			this.ticket = res
			// this.subject = this.ticket.subject
			this.ticket.key = ticketKey
			this.loading = false

			// console.log(this.ticket);
			
		})

		this.supportService.hasClientUpdate(ticketKey, false)
	}

	ngAfterViewInit() {
	
	}
	changeSubject() {
		console.log();
		
		if(this.subject.trim() != this.ticket.subject.trim()) {
			this.supportService.changeTicketSubject(this.ticket, this.subject).subscribe(res => {
			// console.log(this.subject);
			// console.log(this.ticket);
			})	
		}
		
	}
	closeTicket() {
		this.supportService.closeTicket(this.ticket).subscribe(res => {
			this.ticket.status = 1
		})
	}
	
}

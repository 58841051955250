import { Component, OnInit } from '@angular/core';
import {SupportService} from '../../services/support/support.service';
import {AccountService} from '../../services/account.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-platform',
	templateUrl: './platform.component.html',
	styleUrls: ['./platform.component.css']
})
export class PlatformComponent implements OnInit {

	constructor(
		private supportService: SupportService,
		private accountservice: AccountService,
		private route: Router
		) { }

	ngOnInit() {
		
		if(this.accountservice.allowRoles([2])) {
			this.supportService.listenForNotifications().subscribe((res: any) => {
				if(res.newNotification) {
					this.playNotificationSound()
				}
			})	
		}

		this.accountservice.listenForAdminChanges().subscribe((res: any) => {
			if(res.logMeOff) {
				this.accountservice.hasToLogOff(false, this.accountservice.getSession().id)
				this.accountservice.destroySession()
				this.route.navigate([''])
			}
		})
		
	}
	playNotificationSound(){
      let audio = new Audio();
      audio.src = '../assets/sound/organ.m4a'
      audio.load();
      audio.play();
    }



}

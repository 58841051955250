import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-alert',
  templateUrl: './app-alert.component.html',
  styleUrls: ['./app-alert.component.css']
})
export class AppAlertComponent implements OnInit {

   alert = null
  @Input() message: string = '';


  fuckingArray = {
    BillsComponent: {
      name: 'Conta',
      add: 'adicionada',
      edit: 'editada',
      delete: 'excluída'
    },
    ClientsComponent: {
      name: 'Cliente',
      add: 'adicionado',
      edit: 'editado',
      delete: 'excluído'
    },
    FormsComponent: {
      name: 'Formulário',
      add: 'adicionado',
      edit: 'editado',
      delete: 'excluído'
    },
    UsersComponent: {
      name: 'Usuário',
      add: 'adicionado',
      edit: 'editado',
      delete: 'excluído'
    }
  }

  constructor(private activateRoute: ActivatedRoute) { }

  ngOnInit() {
    try {
      this.alert = this.activateRoute.snapshot.paramMap.get('alert');
      var action = this.activateRoute.snapshot.paramMap.get('action');
      var component: any = (this.activateRoute.snapshot.component)
      var route = component.name
      this.message = this.fuckingArray[route].name + ' ' +this.fuckingArray[route][action] + ' com sucesso!'  
    } catch (e) {}
  	
  }

}

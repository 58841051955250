import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Router } from '@angular/router';
import { AccountService } from '../account.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

// import 'rxjs/add/observable/of';

// import 'rxjs/add/operator/map'




@Injectable({
	providedIn: 'root'
})
export class RequestService {
  headers: HttpHeaders

  baseUrl = "https://adminapi.notanova.com.br/";
  // baseUrl = "http://localhost:8000/";


  obs = new Observable(observer => { observer.next() })

  constructor(private http: HttpClient, private router: Router) { }

  makeRequest(endPoint, params, type){
  		var session = JSON.parse(localStorage.getItem('session'));
  		if(session) {
		  	this.headers = new HttpHeaders({
		  		token: session.token
		  	})
  		}
  		var request
  		switch(type){
  			case "post":
  				return this.http.post(this.baseUrl + endPoint, params, { headers: this.headers }).pipe(
  						catchError(this.handleError)
  					);
  			case "get":
  			return this.http.get<any>(this.baseUrl + endPoint, { headers: this.headers }).pipe(
  						catchError((this.handleError))
  					);	;

  			case "put":
  			return this.http.put(this.baseUrl + endPoint, params, { headers: this.headers }).pipe(
  						catchError(this.handleError)
  					);	;
  		}
	}
	handleError(error) {
		if(error.status == 403) {
			localStorage.clear()
		}
	   return throwError(error.error.message);
	 }
	makeExternalRequest(endPoint, params, type){
		switch(type){
			case "post":
				return this.http.post(endPoint, params);

			case "get":
				return this.http.get<any>(endPoint);

			case "put":
				 return this.http.put(endPoint, params);
		}
	}
}

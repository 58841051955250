import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase';
import firestore from 'firebase/firestore'
import { MessageModel, TicketModel } from '../../models/FirebaseModels';
import {Md5} from 'ts-md5/dist/md5';

@Injectable({
	providedIn: 'root'
})
export class SupportService {

	notRef 		= firebase.firestore().collection('notificationsAdmin');
	usersRef 	= firebase.firestore().collection('users');
	ticketsRef 	= firebase.firestore().collection('tickets');
	chatsRef 	 = firebase.firestore().collection('chats');

	/////////////////////
	constructor() { }////////
	/////////////////////

	getTickets(){
		return this.getAllTickets()
	}


	private getAllTickets() {
		
		return new Observable((observer) => {
			this.ticketsRef.orderBy("status").orderBy("timestamp","desc").onSnapshot((querySnapshot) => {
				let res = [];
				querySnapshot.forEach((doc) => {
					let data = doc.data();
					
					res.push({
						key: doc.id,
						chat: data.chat,
						subject: data.subject,
						timestamp: data.timestamp,
						type: data.type,
						userId: data.userId,
						username: data.username,
						closingDate: data.closingDate,
						status: data.status,
						hasClientUpdate: data.hasClientUpdate
					});

				});
				observer.next(res);
			});
		});
	}

	closeTicket(ticket) {
		var lastMessage = "Chamado fechado"
		var closingDate = new Date().getTime()
		
		return new Observable((observer) => {
			this.ticketsRef.doc(ticket.key).update({closingDate: closingDate, status: 1}).then(() => {
				this.sendMessage(ticket, lastMessage, true)
				observer.next();
			});
		});
	}
	makeRandomString(): string {
		var md5 = new Md5()
		md5.appendStr('notanova-admin'+new Date().getTime())
		return String(md5.end())
	}

	getTicket(key: string) {
		return new Observable((observer) => {
			this.ticketsRef.doc(key).onSnapshot((dcSnapshot) => {
				observer.next(dcSnapshot.data())
			})
		})
	}

	getMessages(chatId: string = null) {

		if(chatId) {
			
			return new Observable((observer) => {
				this.chatsRef.doc(chatId).collection("messages").orderBy("timestamp").onSnapshot((querySnapshot) => {
					let res = [];
					querySnapshot.forEach((doc) => {
						let data = doc.data();
						
						res.push({
							isAdmin: data.isAdmin,
							message: data.message,
							timestamp: data.timestamp,
						});
					});
					observer.next(res);
				});
			});
		}
	}

	sendMessage(ticket, text: string, system = false) {
		
		var date = new Date().getTime()
		var message: MessageModel = {
			isAdmin: true,
			message: text,
			timestamp: date,
			system: system
		}

		this.chatsRef.doc(ticket.chat).collection("messages").add(message)
		this.ticketsRef.doc(ticket.key).update({lastMessage: text})
		this.hasAdminUpdate(ticket.key, true)
		this.hasClientUpdate(ticket.key, false)
		this.notifyUser(ticket.userId)
	}

	changeTicketSubject(ticket, newSubject) {
		console.log(ticket)
		var msg = "Descricao alterada para: " + newSubject
		return new Observable((observer) => {
			this.ticketsRef.doc(ticket.key).update({subject: newSubject}).then(() => {
				this.sendMessage(ticket, msg, true)
				observer.next();
			});
		});
	}

	notifyUser(userId) {
		this.usersRef.doc(userId).update({newNotification: true}).then(r => {
			this.usersRef.doc(userId).update({newNotification: false})	
		})
	}
	//copiar os 4 no cliente
	hasClientUpdate(ticketKey, bool) {
		this.ticketsRef.doc(ticketKey).update({hasClientUpdate: bool})
	}
	hasAdminUpdate(ticketKey, bool) {
		this.ticketsRef.doc(ticketKey).update({hasAdminUpdate: bool})
	}
	
	listenForNotifications() {
		return new Observable((observer) => {
			this.notRef.doc("admin").onSnapshot((querySnapshot) => {
				observer.next(querySnapshot.data());
			});
		});
	}

	deleteTickets(ticket: any){
		
		this.ticketsRef.doc(ticket.key).delete().then(function() {})
		this.chatsRef.doc(ticket.chat).delete().then(function(){})
		
	}
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountService } from '../../../../services/account.service';
import { HelperService } from '../../../../services/helper.service';

@Component({
	selector: 'app-user-add',
	templateUrl: './user-add.component.html',
	styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent implements OnInit {

	loading = false
	form: FormGroup
	model: any = {}
	rolesSelect = []
	message: ''
	id
	constructor(private formBuilder: FormBuilder, 
		private accountService: AccountService, 
		private helper: HelperService,
		private activatedRoute: ActivatedRoute,
		private router: Router
		) { }

	ngOnInit() {
		this.id = this.activatedRoute.snapshot.paramMap.get('id');
		if(this.id) {
			this.getUser(this.id)	
		}
		this.helper.getRolesSelect().subscribe(res => {
			this.rolesSelect = res
		})
		this.setupForm()
	}

	getUser(id) {
		this.accountService.get(id).subscribe(res => {
			this.model = res
			this.model.password = ''
			console.log(res)
		})
		// this.model = {name: 'Andrews', role: 3, email: 'asdasd@asdasd.com'}
	}

	setupForm() {
		this.form = this.formBuilder.group({
			'name': [null, Validators.compose([Validators.required])],
			'role': [null, Validators.compose([Validators.required])],
		    'email': [null, Validators.compose([Validators.required, Validators.email])],
		    'password': [null, Validators.required]
	    })
	}
	submit() {
		this.loading = true
		console.log(this.model)
		this.accountService.post(this.model).subscribe(res => {
			this.loading = false
			if(res.status) {
				if(this.id) {
					this.router.navigate(['/platform/users', { alert: 'success', action: 'edit' }]);	
				} else {
					this.router.navigate(['/platform/users', { alert: 'success', action: 'add' }]);	
				}
			}
			this.message = res.message
		})
	}

}

import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import firestore from 'firebase/firestore';

const settings = {timestampsInSnapshots: true};
const config = {
    apiKey: "AIzaSyAeghH50Olac4cYc3Ia2zC4kKsp-EPCYf8",
    authDomain: "notanova-df5be.firebaseapp.com",
    databaseURL: "https://notanova-df5be.firebaseio.com",
    projectId: "notanova-df5be",
    storageBucket: "notanova-df5be.appspot.com",
    messagingSenderId: "349219004389"
  };

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  filters = { sortColumns: '', sortOrder: ''}
   orderBy(columnName: string){
      if(columnName == this.filters.sortColumns){
        if(this.filters.sortOrder == 'ASC'){
          this.filters.sortOrder = 'DESC'
        }else{
          this.filters.sortOrder = 'ASC'
        }
      }else{
        this.filters.sortOrder = 'ASC'
      }
      this.filters.sortColumns = columnName 
    }

    ngOnInit() {
      firebase.initializeApp(config);
      firebase.firestore().settings(settings);
    }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { AccountService } from '../account.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements CanActivate{

	public isLoggedIn: boolean = false;
	
	constructor(private router: Router, private accountService: AccountService) { }

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
		var isLogged = this.accountService.getSession();

		if(!isLogged){
			console.log(isLogged)
			this.router.navigate([''])
			return false
		}
		 return true
	}

}
	
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '../../../services/account.service';
import { SessionModel } from '../../../models/AccountModels';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	loading = false
	form: FormGroup
	message = ''

	model: any = {
		email: 'andrews@create.com',
		password: 'andrews'
	}

	constructor(
		private formBuilder: FormBuilder,
		private accountService: AccountService,
		private router: Router
		) { 

	}

	ngOnInit() {
		this.setupForm()
		if(this.accountService.getSession()) {
			this.router.navigate(['/platform'])
			return
		}
	}

	setupForm() {
		this.form = this.formBuilder.group({
		    'email': [null, Validators.compose([Validators.required, Validators.email])],
		    'password': [null, Validators.required]
	    })
	}

	submit() {
		this.loading = true
		this.accountService.signin(this.model).subscribe((res: SessionModel) => {
			this.loading = false
			this.message = res.message
			if(res.status) {
				this.accountService.saveSession(res)
				this.router.navigate(['/platform'])
			}
			
		})
		
		
	}
}

import { Routes } from '@angular/router';

import { NotFoundComponent } from '../components/shared/not-found/not-found.component';
import { PlatformComponent } from '../components/platform/platform.component';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { RolesService } from '../services/authentication/roles.service';
// Clients
import { ClientsComponent } from '../components/platform/clients/clients/clients.component';
import { DashboardComponent } from '../components/platform/dashboard/dashboard/dashboard.component';

import { TicketComponent } from '../components/platform/support/ticket/ticket.component';
import { ClientEditComponent } from '../components/platform/clients/client-edit/client-edit.component';
import { InvoicesComponent } from '../components/platform/invoices/invoices/invoices.component';
import { FormsComponent } from '../components/platform/forms/forms/forms.component';
import { SupportComponent } from '../components/platform/support/support/support.component';
import { UserAddComponent } from '../components/platform/users/user-add/user-add.component';
import { LoginComponent } from '../components/pages/login/login.component';
import { ForbiddenComponent } from '../components/shared/forbidden/forbidden.component';
import { DataComponent } from '../components/platform/data/data/data.component';

import { UsersComponent } from '../components/platform/users/users/users.component';
import { ClientDetailComponent } from '../components/platform/clients/client-detail/client-detail.component';

export const AppRoutes: Routes = [
{
	path: 'platform',
	component: PlatformComponent,
	children: [
		// clients
		{ path: '', component: DashboardComponent },
		{ path: 'home', component: DashboardComponent },
		{ path: 'dashboard', component: DashboardComponent },
		{ path: 'data', component: DataComponent },
		{ path: 'clients', component: ClientsComponent },
		{ path: 'clients/edit/:id', component: ClientEditComponent },
		{ path: 'clients/detail/:id', component: ClientDetailComponent },

		// invoices
		{ path: 'invoices', component: InvoicesComponent, canActivate: [RolesService], data:{allowedRoles: [3]}},
		{ path: 'forms', component: FormsComponent },
		{ path: 'support', component: SupportComponent, canActivate: [RolesService], data:{allowedRoles: [2]}},
		{ path: 'ticket', component: TicketComponent },

		// users
		{ path: 'users/add', component: UserAddComponent, canActivate: [RolesService], data:{allowedRoles: [2,3]} },
		{ path: 'users', component: UsersComponent },
		{ path: 'users/edit/:id', component: UserAddComponent },
		{ path: '**', component: NotFoundComponent }
	],
	canActivate: [AuthenticationService]
},
{ path: '', component: LoginComponent },
{ path: 'login', component: LoginComponent },
{ path: 'forbidden', component: ForbiddenComponent },
{ path: '**', component: NotFoundComponent }

];
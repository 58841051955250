import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { AppRoutes } from './routes/app.routes';
import { ClientsComponent } from './components/platform/clients/clients/clients.component';
import { PlatformComponent } from './components/platform/platform.component';
import { NotFoundComponent } from './components/shared/not-found/not-found.component';
import { AppAlertComponent } from './components/shared/app-alert/app-alert.component';
import { EmptyEstateComponent } from './components/shared/empty-estate/empty-estate.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { InvoicesComponent } from './components/platform/invoices/invoices/invoices.component';
import { FormsComponent } from './components/platform/forms/forms/forms.component';
import { ClientEditComponent } from './components/platform/clients/client-edit/client-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormEditComponent } from './components/platform/forms/form-edit/form-edit.component';
import { ChatComponent } from './components/shared/chat/chat.component';
import { MessageComponent } from './components/shared/chat/message/message.component';
import { SupportComponent } from './components/platform/support/support/support.component';
import { TicketComponent } from './components/platform/support/ticket/ticket.component';
import { TextMaskModule } from 'angular2-text-mask';
import { LoginComponent } from './components/pages/login/login.component';
import { DashboardComponent } from './components/platform/dashboard/dashboard/dashboard.component';
import { UserAddComponent } from './components/platform/users/user-add/user-add.component';
import { ForbiddenComponent } from './components/shared/forbidden/forbidden.component';
import { UsersComponent } from './components/platform/users/users/users.component';
import { ChartsModule } from 'ng2-charts';
import { ClientDetailComponent } from './components/platform/clients/client-detail/client-detail.component';
import { DataComponent } from './components/platform/data/data/data.component';



@NgModule({
  declarations: [
    AppComponent,
    ClientsComponent,
    PlatformComponent,
    NotFoundComponent, 
    AppAlertComponent,
    EmptyEstateComponent,
    SidebarComponent,
    InvoicesComponent,
    FormsComponent,
    ClientEditComponent,
    FormEditComponent,
    ChatComponent,
    MessageComponent,
    SupportComponent,
    TicketComponent,
    LoginComponent,
    DashboardComponent,
    UserAddComponent,
    ForbiddenComponent,
    UsersComponent,
    ClientDetailComponent,
    DataComponent,
    
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(AppRoutes),
    HttpClientModule,
    CurrencyMaskModule,
    Ng2FlatpickrModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    ChartsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

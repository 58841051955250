import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { HelperService } from './helper.service';

@Injectable({
	providedIn: 'root'
})
export class FormValidatorService {

	constructor(private helper: HelperService) { }

	public cpf(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {

			if (control.value !== undefined && this.helper.checkCPF(control.value)) {
				return null;
			}
			return { 'cpf': true };
		};
	}

	public cnpj() {
		return (control: AbstractControl): { [key: string]: boolean } | null => {

			if (control.value !== undefined && this.helper.checkCNPJ(control.value)) {
				return null;
			}
			return { 'cnpj': true };
		};
	}

	public ie(uf) {

		return (control: AbstractControl): { [key: string]: boolean } | null => {

			if (control.value !== undefined && this.helper.checkIE(control.value, uf)) {
				return null;
			}
			return { 'cnpj': true };
		};
	}
}

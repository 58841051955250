import { Injectable } from '@angular/core';
import { RequestService } from '../request/request.service';
import { HelperService } from '../helper.service';
import { AccountService } from '../account.service';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(private account: AccountService, private helper: HelperService, private request: RequestService) {
    
  }

  list(page, query, id = false, filters: any = {}) {
    
    var url = id ? 'form/list/' + id : 'form/list?page=' + page  + '&q=' + query 
    url += '&sortColumns=' + filters.sortColumns + '&sortOrder=' + filters.sortOrder;

    console.log(url)
    return this.request.makeRequest(url, [], 'get')
  }
}

import { Component, OnInit, Host } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../../../app.component';
import { FormsService } from '../../../../services/forms/forms.service';
import { HelperService } from '../../../../services/helper.service';

@Component({
	selector: 'app-forms',
	templateUrl: './forms.component.html',
	styleUrls: ['./forms.component.css']
})
export class FormsComponent implements OnInit {
	model: any = {}
	columns = [
		{name: 'Formulário', sortingId: 'form', loadingSort: false },
		{name: 'Nome', sortingId: 'name', loadingSort: false },
		{name: 'Email', sortingId: 'email', loadingSort: false },
		{name: 'Telefone', sortingId: 'phone', loadingSort: false },
		{name: 'Status', sortingId: 'status', loadingSort: false },
		{name: 'Observação', sortingId: 'obs', loadingSort: false },
	]
	constructor(

		@Host() private App: AppComponent,  
		private formService: FormsService, 
		private helper: HelperService, 
		private router: ActivatedRoute) {

		this.App.filters = { sortColumns: '', sortOrder: ''}
		var alert = this.router.snapshot.paramMap.get('alert');
	}
	 showBtn = true;
	  query = ""
	  loading = false
	  loadingMore = false
	  page = 1
	  pagination: boolean
	  checkboxArray = []

	 ngOnInit() {
    	this.getItems(true)
  	}

  getItems(refresh = false, sortIndex = -1) {
    if (refresh) {
      // this.page = 1
    } else {
      this.loadingMore = true
    }
    if (sortIndex == -1 && !this.query && !this.loadingMore) {
      this.loading = true
    }

  	 this.formService.list(this.page, this.query, null, this.App.filters).subscribe(
       (data) => {
         console.log(data);
         this.loading = false
        if(sortIndex != -1){
          this.columns[sortIndex].loadingSort = false
        }
    	 	if(refresh){
    	 		this.model.results = data
    	 	}else{
            this.model.status = data.status
            this.model.per_page = data.per_page
            this.model.page = data.page
            this.model.item_count = data.item_count
            this.model.pagination = data.pagination
    	 		data.results.forEach(item => {
    	 			this.model.results.push(item)
    	 		})
    	 	}

    	 	this.page++;
     });
  }

  searchChanged($event){

    this.query = $event.target.value
    if (this.query) {
      this.showBtn = false
    } else {
      this.showBtn = true
    }
    console.log(this.query)
    this.getItems(true)
  }

  // checkboxChange(index){
  //   var id = this.model.results[index].id
  //   var hasItem = this.helper.findObjectByKey(this.checkboxArray, 'client_id', id)
  //   if(hasItem){
  //     this.checkboxArray.splice(hasItem.index, 1)
  //   }else{
  //     this.checkboxArray.push({client_id: id})
  //   }
  //   console.log(this.checkboxArray)
  // }

  // delete(event: any){
  //   // event.preventDefault()
  //   this.clientService.delete(this.checkboxArray).subscribe(
  //     (data: StatusModel) => {
  //       this.getItems(true)
  //   })
  // }

  // deleteId(id){
  //   // event.preventDefault()
  //   console.log(id)
  //   this.clientService.delete([{client_id: id}]).subscribe(
  //     (data: StatusModel) => {
  //       this.getItems(true)
  //   })
  // }

  orderBy($event, index){
    $event.preventDefault()
    var column = this.columns[index].sortingId
    this.App.orderBy(column);
    this.columns[index].loadingSort = true
    this.getItems(true, index)
  }

  onKeyUp(evento){
    console.log();
    // this.valorAtual = evento.target.value
  }

}

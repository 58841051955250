import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { MessageModel, TicketModel } from '../../../models/FirebaseModels';
import { SupportService } from '../../../services/support/support.service';


@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
	message = ''

	
	@Input() ticket
		
	messages: [MessageModel]

	constructor(private supportService: SupportService) { }

	ngOnInit() {
		this.supportService.getMessages(this.ticket.chat).subscribe((res: [MessageModel]) => {
			this.messages = res
		})
	}
	
	sendMessage() {
		if(this.message.trim().length > 0 && this.ticket.status == 0) {
			this.supportService.sendMessage(this.ticket, this.message.trim())
			this.message = ''	
		}
	}
}

import { Injectable } from '@angular/core';
import { RequestService } from '../request/request.service';
import { HelperService } from '../helper.service';
import { AccountService } from '../account.service';
import { SessionModel } from '../../models/AccountModels';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private account: AccountService, private helper: HelperService, private request: RequestService) {
    // this.currentAccount = this.account.getSession()
    // this.user = {
    //   user_id: this.currentAccount.user_id,
    //   subuser_id: this.currentAccount.subuser_id
    // }
  }

  getUserHistory(user_id){
    var url = 'client/getUserHistory/' + user_id 

    return this.request.makeRequest(url, [], 'get')
  }


  addToHistory(user_id,txt){
    var url = 'client/addToHistory/' 

    return this.request.makeRequest(url, {
      user_id: user_id,
      text: txt
    }, 'post')
  }
  

  list(page, query,filter, filters: any = {}) {

    var url = 'client/list?page=' + page + '&q=' + query + '&filter=' + filter
    url += '&sortColumns=' + filters.sortColumns + '&sortOrder=' + filters.sortOrder;

    console.log(url)
    return this.request.makeRequest(url, [], 'get')
  }


  tx2Validator(id){
    return this.request.makeRequest('general/tx2Validator/'+id,{}, 'get')
  }
  changeUserPlan(user_id,newPlan){

    return this.request.makeRequest('client/changeUserPlan', {
      user_id: user_id,
      plan: newPlan,
    }, 'put')


  }

  paymentHistory(id) {

    var url = 'client/paymentHistory/' + id
    return new Observable((observer) => {
      this.request.makeRequest(url, [], 'get').subscribe(res => {
        observer.next(res)
      })
    })
  }

  notanovaPlans() {

    var url = 'client/plans/'
    return new Observable((observer) => {
      this.request.makeRequest(url, [], 'get').subscribe(res => {
        observer.next(res)
      })
    })
  }



  paymentPlanInfo(id) {

    var url = 'client/paymentPlanInfo/' + id
    return new Observable((observer) => {
      this.request.makeRequest(url, [], 'get').subscribe(res => {
        observer.next(res)
      })
    })
  }

  cert(id) {

    var url = 'client/cert/' + id
    return new Observable((observer) => {
      this.request.makeRequest(url, [], 'get').subscribe(res => {
        observer.next(res)
      })
    })
  }



  get(id) {
    var url = 'client/list/' + id
    console.log(url)
    return new Observable((observer) => {
      this.request.makeRequest(url, [], 'get').subscribe(res => {
        observer.next(res[0])
      })
    })

  }

  update(object: any) {
    // delete(object.plano)
    // delete(object.plano_codigo)
    // delete(object.plano_preco)
    // object.cnpj = this.helper.cleanValue(object.cnpj)
    // object.telefone = this.helper.cleanValue(object.telefone)
    // object.inscricao_estadual = this.helper.cleanValue(object.inscricao_estadual)
    // object.inscricao_municipal = this.helper.cleanValue(object.inscricao_municipal)
    // object.end_cep = this.helper.cleanValue(object.end_cep)
    object.a1_pw = this.helper.cleanValue(object.a1_pw)
    object.active = this.helper.cleanValue(object.active)
    object.cnpj = this.helper.cleanValue(object.cnpj)
    object.crt = this.helper.cleanValue(object.crt)
    // object.email = object.email
    // object.end_bairro = this.helper.cleanValue(object.end_bairro)
    object.end_cep = this.helper.cleanValue(object.end_cep)
    object.end_cidade_ibge = this.helper.cleanValue(object.end_cidade_ibge)
    // object.end_complemento = this.helper.cleanValue(object.end_complemento)
    // object.end_estado = this.helper.cleanValue(object.end_estado)
    // object.end_logradouro = this.helper.cleanValue(object.end_logradouro)
    // object.end_numero = this.helper.cleanValue(object.end_numero)
    object.environment = this.helper.cleanValue(object.environment)
    // object.free_days = this.helper.cleanValue(object.free_days)
    // object.id = this.helper.cleanValue(object.id)
    object.inscricao_estadual = this.helper.cleanValue(object.inscricao_estadual)
    object.inscricao_municipal = this.helper.cleanValue(object.inscricao_municipal)
    object.limite_usuarios = this.helper.cleanValue(object.limite_usuarios)
    // object.nome = this.helper.cleanValue(object.nome)
    // object.nome_fantasia = this.helper.cleanValue(object.nome_fantasia)
    object.notas = this.helper.cleanValue(object.notas)
    // object.plano = this.helper.cleanValue(object.plano)
    // object.plano_title = this.helper.cleanValue(object.plano_title)
    // object.preco = this.helper.cleanValue(object.preco)
    // object.razao_social = this.helper.cleanValue(object.razao_social)
    object.status = this.helper.cleanValue(object.status)
    object.telefone = this.helper.cleanValue(object.telefone)
    object.ts_grupo = this.helper.cleanValue(object.ts_grupo)
    object.ts_senha = this.helper.cleanValue(object.ts_senha) 
    object.ts_usuario = this.helper.cleanValue(object.ts_usuario)

    return this.request.makeRequest('client/update/' + object.id, object, 'put')
  }

  delete(id){
    return this.request.makeRequest('client/' + id, {active:false}, 'put')
  }

  freeDays(id,days){
    return this.request.makeRequest('client/' + id, {free_days:days}, 'put')
  }

}

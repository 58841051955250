import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SupportService } from '../../../../services/support/support.service';
import { HelperService } from '../../../../services/helper.service';
import { TicketModel, MessageModel } from '../../../../models/FirebaseModels';

@Component({
	selector: 'app-support',
	templateUrl: './support.component.html',
	styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
	model: any = {}
   columns = [
		{ name: 'Aberto em', sortingId: 'contact', loadingSort: false },
    { name: 'Cliente', sortingId: 'cnpj', loadingSort: false },
    // { name: 'Tipo', sortingId: 'cnpj', loadingSort: false },
    { name: '', sortingId: '', loadingSort: false },
    { name: '', sortingId: '', loadingSort: false },

		{ name: '', sortingId: '', loadingSort: false }
  ]
  showBtn = true;
	query = ""
	loading = false
	loadingMore = false
	page = 1
	pagination: boolean
	checkboxArray = []
	stSelect = []
	ticket: any = {}
	tickets = []

	constructor(
		private supportService: SupportService,
		private router: Router,
		private helper: HelperService) { }

	ngOnInit() {

		this.stSelect = this.helper.getTicketStatusSelect()
		this.supportService.getTickets().subscribe((res: [{}]) => {
			this.tickets = res
				// console.log(this.tickets);
		})
	}

	closeTicket(ticket) {
		this.supportService.closeTicket(ticket).subscribe(res => {
			console.log(res)
		})
	}
	goToChat(ticket) {
		this.router.navigate(['/platform/ticket/', {ticket: JSON.stringify(ticket)}]);
	}
	delete(ticket){
		this.supportService.deleteTickets(ticket)
	}
}

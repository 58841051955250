import { Injectable } from '@angular/core';

import { RequestService } from '../services/request/request.service';


@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  constructor( private request:RequestService ) { }

  userData() {
    var url = 'dashboard/userStats'
    return this.request.makeRequest(url, [], 'get')
  }
}

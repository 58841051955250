import { Injectable } from '@angular/core';
import { conformToMask } from 'angular2-text-mask';
import { CurrencyMaskConfig } from 'ng2-currency-mask/src/currency-mask.config';
import { RequestService } from '../services/request/request.service';
import { FlatpickrOptions } from 'ng2-flatpickr';

@Injectable({
	providedIn: 'root'
})
export class HelperService {
	public dateOptions: FlatpickrOptions = {
		dateFormat: 'd/m/Y',

		defaultDate: new Date(Date.now())
	};
	public dateTimeOptions: FlatpickrOptions = {
		dateFormat: 'd/m/Y H:i',
		defaultDate: new Date(Date.now()),
		enableTime: true,
		time_24hr: true
	};
	public moneyMask: CurrencyMaskConfig = {
		prefix: 'R$ ',
		thousands: '.',
		decimal: ',',
		align: "left",
		allowNegative: false,
		precision: 2,
		suffix: ""
	};
	public floatMask: CurrencyMaskConfig = {
		prefix: '',
		thousands: '.',
		decimal: ',',
		align: "left",
		allowNegative: false,
		precision: 2,
		suffix: ""
	};

	public floatMaskKg: CurrencyMaskConfig = {
		prefix: '',
		thousands: '.',
		decimal: ',',
		align: "left",
		allowNegative: false,
		precision: 3,
		suffix: ""
	};

	public numberMask: CurrencyMaskConfig = {
		prefix: '',
		thousands: '.',
		decimal: '',
		align: "left",
		allowNegative: false,
		precision: 0,
		suffix: ""
	};

	public percentMask: CurrencyMaskConfig = {
		prefix: '',
		thousands: '.',
		decimal: ',',
		align: "left",
		allowNegative: false,
		precision: 2,
		suffix: '%'
	};
	// Mask for interval of days
	public intervalMask: CurrencyMaskConfig = {
		prefix: '',
		thousands: '.',
		decimal: ',',
		align: "left",
		allowNegative: false,
		precision: 0,
		suffix: " dias"
	};

	public weightMask: CurrencyMaskConfig = {
		prefix: '',
		thousands: '.',
		decimal: ',',
		align: "left",
		allowNegative: false,
		precision: 3,
		suffix: " kg"
	};

	public dateMask = [/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

	public cardMask = [/[0-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]

	public phoneMask = ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

	public codeMask = [/[0-9]/, /\d/, /\d/, /\d/]

	public mobileMask = ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

	public cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]

	public rgMask = [/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/]

	public cnpjMask = [/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]

	public ieMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]

	public cepMask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]

	public inscestMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]

	constructor(private request: RequestService) { }

	findObjectByKey(array, key, value) {
		for (var i = 0; i < array.length; i++) {
			if (array[i][key] === value) {
				return { index: i, object: array[i] };
			}
		}
		return null;
	}

	public conformToPhone(number, mobile) {
		var pattern = mobile ? this.mobileMask : this.phoneMask
		var response = conformToMask(number, pattern, {})
		return response.conformedValue
	}

	public conformToCNPJ(value) {
		var response = conformToMask(value, this.cnpjMask, {})
		return response.conformedValue
	}
	public conformToCard(value) {
		var response = conformToMask(value, this.cardMask, {})
		return response.conformedValue
	}
	public conformToCPF(value) {
		var response = conformToMask(value, this.cpfMask, {})
		return response.conformedValue
	}

	public conformToCEP(value) {
		var response = conformToMask(value, this.cepMask, {})
		return response.conformedValue
	}

	public conformToRG(value) {
		var response = conformToMask(value, this.rgMask, {})
		return response.conformedValue
	}

	public conformToPhoneTypes = function (event) {
		var phone = ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
		var cellPhone = ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

		// console.log(event);
		if (event.length > 14) {
			return cellPhone
		}
		return phone
	}

	getFormattedPrice(price: number) {
		return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
	}

	getValueFromString(value): number {
		return value.replace('R$', '').replace('.', '').replace(',', '.').trim()
	}

	getTicketStatusSelect() {
		return [
			{id: -1, name: 'Todos'},
			{id: 0, name: 'Aberto'},
			{id: 1, name: 'Fechado'}
		]
	}

	getPlansSelect() {
		return [
			{id: 100, name: '100 - Plano Emissor R$39,99' },
			{id: 200, name: '200 - Plano Ilimitado R$99,99' },
			{id: 300, name: '300 - Avançado R$299,99' }
		]
	}

	getClientStatusSelect() {
		return [
			{id: 0, name: 'CANCELADO' },
			{id: 1, name: 'PRECISA PAGAR' },
			{id: 2, name: 'PAGO' },
			{id: 3, name: 'AGUARDANDO PAGAMENTO' },
			{id: 4, name: 'PAGAMENTO RECUSADO' }

		]
	}

	getCRTSelect() {
		return [
			{id: 1, name: 'Simples Nacional' },
			{id: 2, name: 'Simples Nacional - excesso de sublimite da receita bruta' },
			{id: 3, name: 'Regime Normal' }
		]
	}

	getEnvironmentSelect() {
		return [
			{id: 1, name: 'PRODUÇÃO' },
			{id: 2, name: 'HOMOLOGAÇÃO' },
		]
	}

	cleanValue(value) {
		if (typeof value !== 'string'){
			value = value.toString()
		}

		var desired = value.replace(/[^\w\s]/gi, '').replace(' ', '')

		return desired
	}

	checkCPF(strCPF) {

		var Soma;
		var Resto;
		var i;
		if(strCPF) {
			strCPF = this.cleanValue(strCPF)
		}
		Soma = 0;
		if (strCPF == "00000000000" || !strCPF)  return false;

		for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
		Resto = (Soma * 10) % 11;

		if ((Resto == 10) || (Resto == 11)) Resto = 0;
		if (Resto != parseInt(strCPF.substring(9, 10))) return false;

		Soma = 0;
		for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
		Resto = (Soma * 10) % 11;

		if ((Resto == 10) || (Resto == 11)) Resto = 0;
		if (Resto != parseInt(strCPF.substring(10, 11))) return false;
		return true;

	}

	checkCNPJ(cnpj) {
		if(!cnpj) return false

		var cnpj = cnpj.replace(/[^\d]+/g, ''); //regex q tira todos caracteres especiais

		if (cnpj == '') {
			//   console.log("vazio");
			return false;
		}

		if (cnpj.length < 14) {
			//   console.log(cnpj.length);
			//   console.log("menos de 14");
			return false;
		}

		// console.log(cnpj);

		// Elimina CNPJs invalidos conhecidos
		if (cnpj == "00000000000000" ||
			cnpj == "11111111111111" ||
			cnpj == "22222222222222" ||
			cnpj == "33333333333333" ||
			cnpj == "44444444444444" ||
			cnpj == "55555555555555" ||
			cnpj == "66666666666666" ||
			cnpj == "77777777777777" ||
			cnpj == "88888888888888" ||
			cnpj == "99999999999999") {
			//   console.log("000000000000000");
			return false;
		}

		// Valida DVs
		var tamanho = cnpj.length - 2
		var numeros = cnpj.substring(0, tamanho);
		var digitos = cnpj.substring(tamanho);
		var soma = 0;
		var pos = tamanho - 7;
		for (var i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2)
				pos = 9;
			// console.log('for1');

		}
		var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
		if (resultado != digitos.charAt(0))
			return false;

		tamanho = tamanho + 1;
		numeros = cnpj.substring(0, tamanho);
		soma = 0;
		pos = tamanho - 7;
		for (i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2)
				pos = 9;
			// console.log('for2');

		}
		resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
		if (resultado != digitos.charAt(1))
			return false;

		return true;
	}

	checkIE(ie,estado) {

		// console.log(estado)
		var estado
		// onde Txt_UF é o ID da UF de seu formulário
		if (!ie || !estado) return false

		var ie = ie.replace(/\./g, "");
		var ie = ie.replace(/\\/g, "");
		var ie = ie.replace(/\-/g, "");
		var ie

		if (ie == "ISENTO")
			return true;
			// console.log('fora',estado,ie);

		switch (estado) {

			case "AC":

				if (ie.length != 13)
					return false;
				var b = 4, soma = 0;
				for (var i = 0; i <= 10; i++) {
					soma += parseInt(ie.charAt(i)) * b;
					--b;
					if (b == 1)
						b = 9;
				}
				dig = 11 - (soma % 11);
				if (dig >= 10)
					dig = 0;
				if (dig != parseInt(ie.charAt(11)))
					return false;
				b = 5;
				soma = 0;
				for (var i = 0; i <= 11; i++) {
					soma += parseInt(ie.charAt(i)) * b;
					--b;
					if (b == 1)
						b = 9;
				}
				dig = 11 - (soma % 11);
				if (dig >= 10)
					dig = 0;
				return (dig == parseInt(ie.charAt(12)));
				// break;

			case "AL":

				if (ie.length != 9)
					return false;
				var b = 9, soma = 0;
				for (var i = 0; i <= 7; i++) {
					soma += parseInt(ie.charAt(i)) * b;
					--b;
				}
				soma *= 10;
				dig = soma - (Math.floor(soma / 11) * 11);
				if (dig == 10)
					dig = 0;
				return (dig == parseInt(ie.charAt(8)));
				// P

			case "AM":

				if (ie.length != 9)
					return false;
				var b = 9, soma = 0;
				for (var i = 0; i <= 7; i++) {
					soma += parseInt(ie.charAt(i)) * b;
					b--;
				}
				if (soma < 11) {
					dig = 11 - soma;
				}
				else {
					i = soma % 11;
					if (i <= 1) {
						dig = 0;
					}
					else {
						dig = 11 - i;
					}
				}
				return (dig == parseInt(ie.charAt(8)));
				// break;

			case "AP":

				if (ie.length != 9)
					return false;
				if (ie.substring(0, 2) != "03")
					return false;
				var p = 0;
				var d = 0;
				var j = ie.substring(1, 8);
				if ((j >= 3000001) && (j <= 3017000)) {
					p = 5;
					d = 0;
				}
				else if ((j >= 3017001) && (j <= 3019022)) {
					p = 9;
					d = 1;
				}
				b = 9;
				soma = p;
				for (var i = 0; i <= 7; i++) {
					soma += parseInt(ie.charAt(i)) * b;
					b--;
				}
				dig = 11 - (soma % 11);
				if (dig == 10) {
					dig = 0;
				}
				else if (dig == 11) {
					dig = d;
				}
				return (dig == parseInt(ie.charAt(8)));
				// break;

			case "BA":

				if (ie.length == 8) {
					var die = ie.substring(0, 8);
					var nro = new Array(8);
					var dig = -1;
					for (var i = 0; i <= 7; i++)
						nro[i] = parseInt(die.charAt(i));
					var NumMod = 0;
					if (String(nro[0]).match(/[0123458]/)) {
						NumMod = 10;
					}
					else {
						NumMod = 11;
					}
					b = 7;
					soma = 0;
					for (i = 0; i <= 5; i++) {
						soma += nro[i] * b;
						b--;
					}
					i = soma % NumMod;
					if (NumMod == 10) {
						if (i == 0) { dig = 0; } else { dig = NumMod - i; }
					}
					else {
						if (i <= 1) { dig = 0; } else { dig = NumMod - i; }
					}
					var resultado = (dig == nro[7]);
					if (!resultado)
						return false;
					b = 8;
					soma = 0;
					for (i = 0; i <= 5; i++) {
						soma += nro[i] * b;
						b--;
					}
					soma += nro[7] * 2;
					i = soma % NumMod;
					if (NumMod == 10) {
						if (i == 0) { dig = 0; } else { dig = NumMod - i; }
					}
					else {
						if (i <= 1) { dig = 0; } else { dig = NumMod - i; }
					}
					return (dig == nro[6]);
				}

				if (ie.length == 9) {
					die = ie.substring(0, 9);
					var nro = new Array(9);
					var dig = -1;
					for (var i = 0; i <= 8; i++)
						nro[i] = parseInt(die.charAt(i));
					var NumMod = 0;
					if (String(nro[0]).match(/[0123458]/)) {
						NumMod = 10;
					}
					else {
						NumMod = 11;
					}
					b = 8;
					soma = 0;
					for (i = 0; i <= 6; i++) {
						soma += nro[i] * b;
						b--;
					}
					i = soma % NumMod;
					if (NumMod == 10) {
						if (i == 0) { dig = 0; } else { dig = NumMod - i; }
					}
					else {
						if (i <= 1) { dig = 0; } else { dig = NumMod - i; }
					}
					resultado = (dig == nro[8]);
					if (!resultado)
						return false;
					b = 9;
					soma = 0;
					for (i = 0; i <= 6; i++) {
						soma += nro[i] * b;
						b--;
					}
					soma += nro[8] * 2;
					i = soma % NumMod;
					if (NumMod == 10) {
						if (i == 0) { dig = 0; } else { dig = NumMod - i; }
					}
					else {
						if (i <= 1) { dig = 0; } else { dig = NumMod - i; }
					}
					return (dig == nro[7]);
				}

				return false;
				// break;

			case "CE":

				if (ie.length != 9)
					return false;
				die = ie;
				var nro = Array(9);
				for (var i = 0; i <= 8; i++)
					nro[i] = parseInt(die[i]);
				b = 9;
				soma = 0;
				for (i = 0; i <= 7; i++) {
					soma += nro[i] * b;
					b--;
				}
				dig = 11 - (soma % 11);
				if (dig >= 10)
					dig = 0;
				return (dig == nro[8]);
				// break;

			case "DF":

				if (ie.length != 13)
					return false;
				var nro = new Array(13);
				for (var i = 0; i <= 12; i++)
					nro[i] = parseInt(ie.charAt(i));
				b = 4;
				soma = 0;
				for (i = 0; i <= 10; i++) {
					soma += nro[i] * b;
					b--;
					if (b == 1)
						b = 9;
				}
				dig = 11 - (soma % 11);
				if (dig >= 10)
					dig = 0;
				if (dig != nro[11])
					return false;
				b = 5;
				soma = 0;
				for (i = 0; i <= 11; i++) {
					soma += nro[i] * b;
					b--;
					if (b == 1)
						b = 9;
				}
				dig = 11 - (soma % 11);
				if (dig >= 10)
					dig = 0;
				return (dig == nro[12]);
				// break;

			case "ES":

				if (ie.length != 9)
					return false;
				var nro = new Array(9);
				for (var i = 0; i <= 8; i++)
					nro[i] = parseInt(ie.charAt(i));
				b = 9;
				soma = 0;
				for (i = 0; i <= 7; i++) {
					soma += nro[i] * b;
					b--;
				}
				i = soma % 11;
				if (i < 2) { dig = 0; } else { dig = 11 - i; }
				return (dig == nro[8]);
				// break;

			case "GO":

				if (ie.length != 9)
					return false;
				var s = ie.substring(0, 2);
				if ((s == '10') || (s == '11') || (s == '15')) {
					var nro = new Array(9);
					for (var i = 0; i <= 8; i++)
						nro[i] = parseInt(ie.charAt(i));
					//n = Math.floor(ie / 10);
					n = parseInt(ie.substring(0, 7));
					if (n = 11094402) {
						if ((nro[8] == 0) || (nro[8] == 1))
							return true;
					}
					b = 9;
					soma = 0;
					for (i = 0; i <= 7; i++) {
						soma += nro[i] * b;
						b--;
					}
					i = soma % 11;
					if (i == 0)
						dig = 0;
					else {
						if (i == 1) {
							if ((n >= 10103105) && (n <= 10119997))
								dig = 1;
							else
								dig = 0;
						}
						else
							dig = 11 - i;
					}
					return (dig == nro[8]);
				}
				return false;
				// break;

			case "MA":

				if (ie.length != 9)
					return false;
				if (ie.substring(0, 2) != "12")
					return false;
				var nro = new Array(9);
				for (var i = 0; i <= 8; i++)
					nro[i] = parseInt(ie.charAt(i));
				b = 9;
				soma = 0;
				for (i = 0; i <= 7; i++) {
					soma += nro[i] * b;
					b--;
				}
				i = soma % 11;
				if (i <= 1) { dig = 0; } else { dig = 11 - i; }
				return (dig == nro[8]);
				// break;

			case "MG":

				if (ie.length != 13)
					return false;
				var dig1 = ie.substring(11, 12);
				var dig2 = ie.substring(12, 13);
				var inscC = ie.substring(0, 3) + '0' + ie.substring(3, 11);
				var insc = inscC.split('');
				var npos = 11;
				var i = 1;
				var ptotal = 0;
				var psoma = 0;
				while (npos >= 0) {
					i++;
					psoma = parseInt(insc[npos]) * i;
					if (psoma >= 10)
						psoma -= 9;
					ptotal += psoma;
					if (i == 2)
						i = 0;
					npos--;
				}
				var nresto = ptotal % 10;
				if (nresto == 0)
					nresto = 10;
				nresto = 10 - nresto;
				if (nresto != parseInt(dig1))
					return false;
				npos = 11;
				i = 1;
				ptotal = 0;
				var is = ie.split('');
				while (npos >= 0) {
					i++;
					if (i == 12)
						i = 2;
					ptotal += parseInt(is[npos]) * i;
					npos--;
				}
				nresto = ptotal % 11;
				if ((nresto == 0) || (nresto == 1))
					nresto = 11;
				nresto = 11 - nresto;
				return (nresto == parseInt(dig2));
				// break;

			case "MS":

				if (ie.length != 9)
					return false;
				if (ie.substring(0, 2) != "28")
					return false;
				var nro = new Array(9);
				for (var i = 0; i <= 8; i++)
					nro[i] = parseInt(ie.charAt(i));
				b = 9;
				soma = 0;
				for (i = 0; i <= 7; i++) {
					soma += nro[i] * b;
					b--;
				}
				i = soma % 11;
				if (i <= 1) { dig = 0; } else { dig = 11 - i; }
				return (dig == nro[8]);
				// break;

			case "MT":

				if (ie.length != 11)
					return false;
				die = ie;
				var nro = new Array(11);
				for (var i = 0; i <= 10; i++)
					nro[i] = parseInt(die[i]);
				b = 3;
				soma = 0;
				for (i = 0; i <= 9; i++) {
					soma += nro[i] * b;
					b--;
					if (b == 1)
						b = 9;
				}
				i = soma % 11;
				if (i <= 1) { dig = 0; } else { dig = 11 - i; }
				return (dig == nro[10]);
				// break;

			case "PA":

				if (ie.length != 9)
					return false;
				if (ie.substring(0, 2) != '15')
					return false;
				var nro = new Array(9);
				for (var i = 0; i <= 8; i++)
					nro[i] = parseInt(ie.charAt(i));
				b = 9;
				soma = 0;
				for (i = 0; i <= 7; i++) {
					soma += nro[i] * b;
					b--;
				}
				i = soma % 11;
				if (i <= 1)
					dig = 0;
				else
					dig = 11 - i;
				return (dig == nro[8]);
				// break;

			case "PB":

				if (ie.length != 9)
					return false;
				var nro = new Array(9);
				for (var i = 0; i <= 8; i++)
					nro[i] = parseInt(ie.charAt(i));
				b = 9;
				soma = 0;
				for (i = 0; i <= 7; i++) {
					soma += nro[i] * b;
					b--;
				}
				i = soma % 11;
				if (i <= 1)
					dig = 0;
				else
					dig = 11 - i;
				return (dig == nro[8]);
				// break;

			case "PE":

				// IE antiga com 14 digitos
				if (ie.length == 14) {
					var nro = new Array(14);
					for (var i = 0; i <= 13; i++)
						nro[i] = parseInt(ie.charAt(i));
					b = 5;
					soma = 0;
					for (i = 0; i <= 12; i++) {
						soma += nro[i] * b;
						b--;
						if (b == 0)
							b = 9;
					}
					dig = 11 - (soma % 11);
					if (dig > 9)
						dig = dig - 10;
					return (dig == nro[13]);
				}

				// IE nova com 9 digitos
				if (ie.length == 9) {
					var nro = new Array(9);
					for (var i = 0; i <= 8; i++)
						nro[i] = parseInt(ie.charAt(i));
					b = 8;
					soma = 0;
					for (i = 0; i <= 6; i++) {
						soma += nro[i] * b;
						b--;
					}
					i = soma % 11;
					if (i <= 1) { dig = 0; } else { dig = 11 - i; }
					if (dig != nro[7])
						return false;
					b = 9;
					soma = 0;
					for (i = 0; i <= 7; i++) {
						soma += nro[i] * b;
						b--;
					}
					i = soma % 11;
					if (i <= 1) { dig = 0; } else { dig = 11 - i; }
					return (dig == nro[8]);
				}

				return false;
				// break;

			case "PI":

				if (ie.length != 9)
					return false;
				var nro = new Array(9);
				for (var i = 0; i <= 8; i++)
					nro[i] = parseInt(ie.charAt(i));
				b = 9;
				soma = 0;
				for (i = 0; i <= 7; i++) {
					soma += nro[i] * b;
					b--;
				}
				i = soma % 11;
				if (i <= 1) { dig = 0; } else { dig = 11 - i; }
				return (dig == nro[8]);
				// break;

			case "PR":

				if (ie.length != 10)
					return false;
				var nro = new Array(10);
				for (var i = 0; i <= 9; i++)
					nro[i] = parseInt(ie.charAt(i));
				b = 3;
				soma = 0;
				for (i = 0; i <= 7; i++) {
					soma += nro[i] * b;
					b--;
					if (b == 1)
						b = 7;
				}
				i = soma % 11;
				if (i <= 1)
					dig = 0;
				else
					dig = 11 - i;
				if (dig != nro[8])
					return false;
				b = 4;
				soma = 0;
				for (i = 0; i <= 8; i++) {
					soma += nro[i] * b;
					b--;
					if (b == 1)
						b = 7;
				}
				i = soma % 11;
				if (i <= 1)
					dig = 0;
				else
					dig = 11 - i;
				return (dig == nro[9]);
				// break;

			case "RJ":

				if (ie.length != 8)
					return false;
				var nro = new Array(8);
				for (var i = 0; i <= 7; i++)
					nro[i] = parseInt(ie.charAt(i));
				b = 2;
				soma = 0;
				for (i = 0; i <= 6; i++) {
					soma += nro[i] * b;
					b--;
					if (b == 1)
						b = 7;
				}
				i = soma % 11;
				if (i <= 1) { dig = 0; } else { dig = 11 - i; }
				return (dig == nro[7]);
				// break;

			case "RN":

				if (ie.substring(0, 2) != '20')
					return false;

				// IE com 9 digitos
				if (ie.length == 9) {
					var nro = new Array(9);
					for (var i = 0; i <= 8; i++)
						nro[i] = parseInt(ie.charAt(i));
					b = 9;
					soma = 0;
					for (i = 0; i <= 7; i++) {
						soma += nro[i] * b;
						b--;
					}
					soma *= 10;
					dig = soma % 11;
					if (dig == 10)
						dig = 0;
					return (dig == nro[8]);
				}

				// IE com 10 digitos
				if (ie.length == 10) {
					var nro = new Array(10);
					for (var i = 0; i <= 9; i++)
						nro[i] = parseInt(ie.charAt(i));
					b = 10;
					soma = 0;
					for (i = 0; i <= 8; i++) {
						soma += nro[i] * b;
						b--;
					}
					soma *= 10;
					dig = soma % 11;
					if (dig == 10)
						dig = 0;
					return (dig == nro[9]);
				}

				return false;
				// break;

			case "RO":

				if (ie.length != 14)
					return false;
				var nro = new Array(14);
				b = 6;
				soma = 0;
				for (var i = 0; i <= 12; i++) {
					nro[i] = parseInt(ie.charAt(i));
					soma += nro[i] * b;
					b--;
					if (b == 1)
						b = 9;
				}
				dig = 11 - (soma % 11);
				if (dig >= 10)
					dig = dig - 10;
				return (dig == parseInt(ie.charAt(13)));
				// break;

			case "RR":

				if (ie.length != 9)
					return false;
				if (ie.substring(0, 2) != "24")
					return false;
				var nro = new Array(9);
				for (var i = 0; i <= 8; i++)
					nro[i] = parseInt(ie.charAt(i));
				var soma = 0;
				var n = 0;
				for (i = 0; i <= 7; i++)
					soma += nro[i] * ++n;
				dig = soma % 9;
				return (dig == nro[8]);
				// break;

			case "RS":

				if (ie.length != 10)
					return false;
				var nro = new Array(10);
				for (var i = 0; i <= 9; i++)
					nro[i] = parseInt(ie.charAt(i));
				b = 2;
				soma = 0;
				for (i = 0; i <= 8; i++) {
					soma += nro[i] * b;
					b--;
					if (b == 1)
						b = 9;
				}
				dig = 11 - (soma % 11);
				if (dig >= 10)
					dig = 0;
				return (dig == nro[9]);
				// break;

			case "SC":

				if (ie.length != 9)
					return false;
				var nro = new Array(9);
				for (var i = 0; i <= 8; i++)
					nro[i] = parseInt(ie.charAt(i));
				b = 9;
				soma = 0;
				for (i = 0; i <= 7; i++) {
					soma += nro[i] * b;
					b--;
				}
				i = soma % 11;
				if (i <= 1)
					dig = 0;
				else
					dig = 11 - i;
				return (dig == nro[8]);
				// break;

			case "SE":

				if (ie.length != 9)
					return false;
				var nro = new Array(9);
				for (var i = 0; i <= 8; i++)
					nro[i] = parseInt(ie.charAt(i));
				b = 9;
				soma = 0;
				for (i = 0; i <= 7; i++) {
					soma += nro[i] * b;
					b--;
				}
				dig = 11 - (soma % 11);
				if (dig >= 10)
					dig = 0;
				return (dig == nro[8]);
				// break;

			case "SP":
console.log(ie.length);

				if (ie.length != 12)

				return false;
				var nro = new Array(12);
				for (var i = 0; i <= 11; i++)

				nro[i] = parseInt(ie.charAt(i));
				soma = (nro[0] * 1) + (nro[1] * 3) + (nro[2] * 4) + (nro[3] * 5) + (nro[4] * 6) + (nro[5] * 7) + (nro[6] * 8) + (nro[7] * 10);
				dig = soma % 11;
				if (dig >= 10)
					dig = 0;
				if (dig != nro[8])
					return false;
				soma = (nro[0] * 3) + (nro[1] * 2) + (nro[2] * 10) + (nro[3] * 9) + (nro[4] * 8) + (nro[5] * 7) + (nro[6] * 6) + (nro[7] * 5) + (nro[8] * 4) + (nro[9] * 3) + (nro[10] * 2);
				dig = soma % 11;
				if (dig >= 10)
					dig = 0;
				return (dig == nro[11]);
				// break;

			case "TO":

				if (ie.length != 11)
					return false;
				s = ie.substring(2, 4);
				if ((s != "01") && (s != "02") && (s != "03") && (s != "99"))
					return false;
				var nro = new Array(11);
				b = 9;
				soma = 0;
				for (var i = 0; i <= 9; i++) {
					nro[i] = parseInt(ie.charAt(i));
					if (i != 2 && i != 3) {
						soma += nro[i] * b;
						b--;
					}
				}
				var resto = soma % 11;
				if (resto < 2) { dig = 0; } else { dig = 11 - resto; }
				return (dig == parseInt(ie.charAt(10)));
				// break;

			default:
				return false;

		}
	}

	getCities(uf) {
		return this.request.makeRequest('general/cities/' + uf, [], 'get')
	}
	getStates() {
		return this.request.makeRequest('general/states', [], 'get')
	}
	getCEP(cep) {
		return this.request.makeExternalRequest("https://viacep.com.br/ws/" + cep + "/json", [], "get")
	}
	getRolesSelect() {
		return this.request.makeRequest('users/roles', [], 'get')
	}

	getDashboardCards() {
		return this.request.makeRequest('dashboard/cards', [], 'get')
	}

	getDashboardUserGrowth() {
		return this.request.makeRequest('dashboard/userGrowth', [], 'get')
	}
	getDashboardPayingUsersGrowth() {
		return this.request.makeRequest('dashboard/payingUsersGrowth', [], 'get')
	}


}

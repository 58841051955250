import { Injectable } from '@angular/core';
import { RequestService } from '../request/request.service';
import { HelperService } from '../helper.service';
import { AccountService } from '../account.service';


@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  constructor(private account: AccountService, private helper: HelperService, private request: RequestService) {
    
  }
  list(page, query, id = false, user_id = false , filters: any = {}) {
    
    var url = id ? 'notas/list/' + id : 'notas/list?page=' + page  + '&q=' + query  + '&user_id=' + user_id
    url += '&sortColumns=' + filters.sortColumns + '&sortOrder=' + filters.sortOrder;

    console.log(url)
    return this.request.makeRequest(url, [], 'get')
  }

  tries(page, query, id = false, user_id = false , filters: any = {}) {
    
    var url = id ? 'notas/tries/' + id : 'notas/tries?page=' + page  + '&q=' + query  + '&user_id=' + user_id
    url += '&sortColumns=' + filters.sortColumns + '&sortOrder=' + filters.sortOrder;

    console.log(url)
    return this.request.makeRequest(url, [], 'get')
  }

 
}

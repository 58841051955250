import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientsService } from '../../../../services/clients/clients.service';
import { FormValidatorService } from '../../../../services/form-validator.service';
import { HelperService } from '../../../../services/helper.service';
import { ClientModel, ClientResultModel } from '../../../../models/ClientModels';

@Component({
	selector: 'app-client-edit',
	templateUrl: './client-edit.component.html',
	styleUrls: ['./client-edit.component.css']
})
export class ClientEditComponent implements OnInit {

	loadingCEP = false

	statesSelect: any;
	plansSelect = []
	statusSelect = []
	crtSelect = []
	environmentSelect = []

	form: FormGroup
	model: any = {
	}

	certificado = 'a1';
	id = null;
	user_id = null
	loading = false;
	citiesSelect: any;

	constructor(
		private activatedRoute: ActivatedRoute,
		private clientsService: ClientsService,
		private formBuilder: FormBuilder,
		private formValidator: FormValidatorService,
		public helper: HelperService
	) {

	}
	ngOnInit() {
		this.user_id = this.activatedRoute.snapshot.paramMap.get('id');
		console.log(this.user_id)
		this.loading = true
		if (this.user_id) {
			this.clientsService.get(this.user_id).subscribe((data: ClientResultModel) => {
				console.log(data);
				console.log(this.model.certificado);
			})
		}
		this.setupSelects()
		this.setupForm()
		this.id = this.activatedRoute.snapshot.paramMap.get('id');
		this.getClient(this.id)

	}

	setupSelects() {
		this.plansSelect = this.helper.getPlansSelect()
		this.statusSelect = this.helper.getClientStatusSelect()
		this.crtSelect = this.helper.getCRTSelect()
		this.environmentSelect = this.helper.getEnvironmentSelect()

		this.helper.getStates().subscribe((data: any) => {
			this.statesSelect = data;
		});

	}

	cityChanged($event){
		if(typeof this.citiesSelect != 'undefined'){
			for (let i = 0; i < this.citiesSelect.length; i++) {
				if(parseInt(this.citiesSelect[i].codigo) == parseInt($event)){
					this.model.end_cidade = this.citiesSelect[i].municipio;
				} else {
				}
			}
		}
		console.log(this.model.end_cidade)
		console.log(this.model.end_cidade_ibge)
	}

	stateChanged() {
		console.log(this.model.end_estado)
		this.form.controls.inscricao_estadual.setValidators([this.formValidator.ie(this.model.end_estado)]);
		this.form.controls.inscricao_estadual.updateValueAndValidity();

		this.helper.getCities(this.model.end_estado).subscribe((data: any) => {
			console.log(data);
			this.citiesSelect = data;
		});

	}

	getClient(id) {
		this.clientsService.get(id).subscribe(res => {
			this.model = res
			this.stateChanged();

			this.cityChanged(this.model.end_cidade_ibge);


			// console.log(res)
		})
	}





	setupForm() {
		this.form = this.formBuilder.group({
			'ts_grupo': [null, Validators.compose([Validators.required])],
			'ts_senha': [null, Validators.compose([Validators.required])],
			'ts_usuario': [null, Validators.compose([Validators.required])],
			'a1_pw': [null, Validators.compose([Validators.required])],
			'environment': [null, Validators.compose([Validators.required])],
			'status': [null, Validators.compose([Validators.required])],
			'crt': [null, Validators.compose([Validators.required])],
			'razao_social': [null, Validators.compose([Validators.required])],
			'nome_fantasia': [null, Validators.compose([Validators.required])],
			'cnpj': [null, Validators.compose([this.formValidator.cnpj()])],
			'inscricao_estadual': [null, Validators.compose([this.formValidator.ie(this.model.end_estado)])],
			'inscricao_municipal': [null, Validators.compose([Validators.required])],
			'email': [null, Validators.compose([Validators.required, Validators.email])],
			'nome': [null, Validators.compose([Validators.required])],
			'telefone': [null, Validators.compose([Validators.required])],
			'end_logradouro': [null, Validators.compose([Validators.required])],
			'end_numero': [null, Validators.compose([Validators.required])],
			'end_complemento': [null, Validators.compose([Validators.required])],
			'end_bairro': [null, Validators.compose([Validators.required])],
			'end_estado': [null, Validators.compose([Validators.required])],
			'end_cidade_ibge': [null, Validators.compose([Validators.required])],
			'end_cidade': [null, Validators.compose([Validators.required])],
			'end_cep': [null, Validators.compose([Validators.required])],
			'plano': [null, Validators.compose([Validators.required])],
			'certificado': [null, Validators.compose([Validators.required])],
			'pm_subs_id': [null],
			'notas_gratuita': [null],

		})
	}


	cepChanged($event) {
		if ($event) {
			var cep = $event.replace(/_/g, "");
			if (cep.length == 9) {
				this.loadingCEP = true
				console.log('loading')
				this.helper.getCEP(cep).subscribe(res => {
					console.log(res)
					this.model.end_logradouro = res.logradouro
					this.model.end_complemento = res.complemento
					this.model.end_cidade = res.localidade
					this.model.end_cidade_ibge = res.ibge
					this.model.end_bairro = res.bairro

					//fazer o search no selet de  estado

					console.log(this.model)
				})
			}
		}
	}

	post() {
		console.log(this.model)

		this.clientsService.update(this.model).subscribe(res => {
			if (res.status) {
				
			} else {
				console.log("deu ruim");

			}
		})

	}

	delete(){
		this.clientsService.delete(this.user_id).subscribe((data: ClientResultModel) => {
			console.log(data);
		})
	}
}

import { Component, OnInit, Host } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../../../app.component';
import { InvoicesService } from '../../../../services/invoices/invoices.service';
import { HelperService } from '../../../../services/helper.service';

@Component({
	selector: 'app-invoices',
	templateUrl: './invoices.component.html',
	styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
	model: any = {}
	columns = [
		{name: 'Id', sortingId: 'cnpj', loadingSort: false },
		{name: 'CNPJ / CPF', sortingId: 'cnpj', loadingSort: false },
		{name: 'Nome', sortingId: 'nomefantasia', loadingSort: false },
		{name: 'Contato', sortingId: 'contact', loadingSort: false },
		{name: 'Pagamento', sortingId: 'payment', loadingSort: false },
		{name: 'Tecnospeed', sortingId: 'tecnospeed', loadingSort: false },
	]

	showBtn = true;
	query = ""
	loading = false
	loadingMore = false
	page = 1
	pagination: boolean
	checkboxArray = []

	constructor(

		@Host() private App: AppComponent,  
		private invoiceService: InvoicesService, 
		private helper: HelperService, 
		private router: ActivatedRoute) {

		this.App.filters = { sortColumns: '', sortOrder: ''}
		var alert = this.router.snapshot.paramMap.get('alert');
	}

	ngOnInit() {
		this.getItems()
	}

	getItems(refresh = false, sortIndex = -1) {
		if (refresh) {
			// this.page = 1
		} else {
			this.loadingMore = true
		}
		if (sortIndex == -1 && !this.query && !this.loadingMore) {
			this.loading = true
		}

		this.invoiceService.list(this.page, this.query, null).subscribe(
			(data) => {
				// console.log(data);
				// this.loading = false
				// if(sortIndex != -1){
				// 	this.columns[sortIndex].loadingSort = false
				// }
				// if(refresh){
				// 	this.model = data
				// }else{
				// 	this.model.status = data.status
				// 	this.model.per_page = data.per_page
				// 	this.model.page = data.page
				// 	this.model.item_count = data.item_count
				// 	this.model.pagination = data.pagination
				// 	data.results.forEach(item => {
				// 		this.model.results.push(item)
				// 	})
				// }

				// this.page++;
			});
	}

}

import { Component, OnInit, Compiler } from '@angular/core';
import { SessionModel } from '../../../models/AccountModels';
import { Router } from '../../../../../node_modules/@angular/router';
import { AccountService } from '../../../services/account.service';
import { HelperService } from '../../../services/helper.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  settingsStatus: string = ''
  localStorage_name = ''
  localStorage_name_fantasia = ''
  session: any;


  isSupport = false
  isSales = false
  isAdmin = false

  constructor(private router: Router, private _accountService: AccountService, private _helper: HelperService, private _compiler: Compiler) {
    this.session = _accountService.getSession()
    // console.log(this.session)
  }

  ngOnInit() {
    this.isSupport = this._accountService.allowRoles([2])
    this.isSales = this._accountService.allowRoles([3])
    this.isAdmin = this._accountService.allowRoles([3])
    var session = this._accountService.getSession()
    this.localStorage_name = session.nombre
  }

  ngAfterViewInit() {

  }
  logoff(event) {
    // console.log(this._compiler);
    event.preventDefault()
    // console.log(this._accountService);
    this._accountService.destroySession();
    // console.log(this._accountService);
    window.location.href = ''
    // this.router.navigate(['']);

  }

  setStatus() {

  }

}

import { Component, OnInit, Input } from '@angular/core';
import { MessageModel, TicketModel } from '../../../../models/FirebaseModels';
import { SupportService } from '../../../../services/support/support.service';
@Component({
	selector: 'app-message',
	templateUrl: './message.component.html',
	styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

	@Input() messages: [MessageModel]

	constructor(private supportService: SupportService) { 
		console.log(this.messages);

	}

	ngOnInit() {
		
	}

}

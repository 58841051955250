import { Component, OnInit, Host } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../../../app.component';
import { StatusModel } from '../../../../models/SharedModels';
import { ClientsService } from '../../../../services/clients/clients.service';
import { HelperService } from '../../../../services/helper.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  model: any = {}
  columns = [
    { name: 'Id', sortingId: 'cnpj', loadingSort: false },
    { name: 'Email / Telefone', sortingId: 'cnpj', loadingSort: false },
    { name: 'CNPJ / CPF', sortingId: 'nomefantasia', loadingSort: false },
    { name: 'Nome da empresa', sortingId: 'contact', loadingSort: false },
    { name: '', sortingId: '', loadingSort: false },
    { name: 'Pagamento', sortingId: 'payment', loadingSort: false },
    { name: 'Tecnospeed', sortingId: 'tecnospeed', loadingSort: false },
  ]

  subscriberOrAll = true;

  type = 1
  showBtn = true;
  query = ""
  loading = false
  loadingMore = false
  page = 1
  pagination: boolean
  checkboxArray = []

  badge

  filter = 'all';

  constructor(

    @Host() private App: AppComponent,
    private clientService: ClientsService,
    private helper: HelperService,
    private router: ActivatedRoute) {

    this.App.filters = { sortColumns: '', sortOrder: '' }
    var alert = this.router.snapshot.paramMap.get('alert');
  }

  ngOnInit() {
    this.getItems(true)



    this.helper.getDashboardCards().subscribe(res => {
      this.badge = res

      this.badge.convertion = (this.badge.usuarios_pagantes/this.badge.usuarios_nao_pagantes*100).toFixed(2);
    })


  }

  refresh(paying = false) {

    if (paying) {
      this.filter = 'paying'
    } else {
      this.filter = 'all'
    }

    this.getItems(true)
  }

  getItems(refresh = false, sortIndex = -1, isSearching = false) {
    if (refresh) {
      // this.page = 1
    } else {
      this.loadingMore = true
    }
    if (sortIndex == -1 && !isSearching && !this.loadingMore) {
      this.loading = true
    }

    this.clientService.list(this.page, this.query, this.filter, this.App.filters).subscribe(
      (data) => {
        this.loading = false
        if (sortIndex != -1) {
          this.columns[sortIndex].loadingSort = false
        }
        if (refresh) {
          this.model.results = data
          
        } else {
          this.model.status = data.status
          this.model.per_page = data.per_page
          this.model.page = data.page
          this.model.item_count = data.item_count
          this.model.pagination = data.pagination
          data.results.forEach(item => {
            this.model.results.push(item)

          })
        }

        this.page++;
      });
  }






  searchChanged($event) {

    this.query = $event.target.value
    // if (this.query) {
      this.showBtn = false
    // } else {
    //   this.showBtn = true
    // }
    this.getItems(true, -1, true)
  }

  checkboxChange(index) {
    var id = this.model.results[index].id
    var hasItem = this.helper.findObjectByKey(this.checkboxArray, 'client_id', id)
    if (hasItem) {
      this.checkboxArray.splice(hasItem.index, 1)
    } else {
      this.checkboxArray.push({ client_id: id })
    }
    console.log(this.checkboxArray)
  }

  tabClicked(type ) {
    console.log(type);

    if (type == 'todos') {
      this.type = 1
      this.filter = 'all'
      this.getItems(true, -1)
      this.subscriberOrAll = true
    }
    if (type == 'assinantes') {
      this.type = 2
      this.filter = 'paying'
      this.getItems(true, -1)
      this.subscriberOrAll = false
    }

    this.page = 1;
  }

  orderBy($event, index) {
    $event.preventDefault()
    var column = this.columns[index].sortingId
    this.App.orderBy(column);
    this.columns[index].loadingSort = true
    this.getItems(true, index)
  }

  onKeyUp(evento) {
    console.log();
    // this.valorAtual = evento.target.value
  }

  contacted(user_id,txt = 'Entrei em contato. (auto)'){
    this.clientService.addToHistory(user_id,txt).subscribe(res => {
      console.log(res)
     this.getItems(true)

  })
  }

}

import { Injectable } from '@angular/core';
import { RequestService } from './request/request.service';
import { SessionModel } from '../models/AccountModels';
import { UserModel } from '../models/UserModels';
import { HelperService } from './helper.service';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import firestore from 'firebase/firestore'

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    adminUsersRef = firebase.firestore().collection('adminUsers');
    constructor(private request: RequestService, private helper: HelperService) { }

    signin(params) {
        return this.request.makeRequest('login/login', params, 'post')
    }
    post(model){
        return model.id ? this.update(model) : this.register(model)
    }
    register(params) {

        return new Observable(observer => {
            this.request.makeRequest('login/register', params, 'post').subscribe(res => {
                this.createFirebaseAdminUser(res.id.toString())
                observer.next(res)
            })
        }) 
    }
    hasToLogOff(has, id) {
        this.adminUsersRef.doc(id.toString()).update({logMeOff: has})
    }
    update(params) {
        this.hasToLogOff(true, params.id.toString())
        if(params.password.trim().length == '') {
            console.log('eliminando o password')
            delete(params.password)
        }
        console.log(params)
        return this.request.makeRequest('users/'+params.id, params, 'put')
    }
    forgotPassword(params) {
        return this.request.makeRequest('Login/forgotPassword', params, 'post')
    }
    passwordReset(params) {
        return this.request.makeRequest('', params, 'post')
    }

    saveSession(data: SessionModel) {
        localStorage.setItem('session', JSON.stringify(data));
    }
    destroySession() {
        localStorage.clear()
    }
    getSession(): SessionModel {
        return JSON.parse(localStorage.getItem('session'));
    }
    getUser(): UserModel {
        return JSON.parse(localStorage.getItem('user'));
    }

    allowRoles(roles: Array<number>): boolean {
        var session = this.getSession()
        return session && (session.role == 1 || roles.includes(session.role))
    }
    get(id) {
        return new Observable(observer => {
            this.request.makeRequest('users/list/'+id, [], 'get').subscribe(res => {
                observer.next(res[0])  
            })
        })    
    }
    list() {
        return this.request.makeRequest('users/list', [], 'get')
    }


    listenForAdminChanges() {
        return new Observable((observer) => {
            this.adminUsersRef.doc(String(this.getSession().id)).onSnapshot((querySnapshot) => {
                observer.next(querySnapshot.data());
            });
        });
    }

    createFirebaseAdminUser(id: string) {
        this.adminUsersRef.doc(id).set({hasNotification: false, logMeOff: false})
    }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientsService } from '../../../../services/clients/clients.service';
import { FormValidatorService } from '../../../../services/form-validator.service';
import { HelperService } from '../../../../services/helper.service';
import { InvoicesService } from '../../../../services/invoices/invoices.service';

import { ClientModel, ClientResultModel } from '../../../../models/ClientModels';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss']
})

export class ClientDetailComponent implements OnInit {

  type = 1
  objectKeys = Object.keys;


  alterPlan = false;

  plans : any = [];

  history = [];
  
  validatorLoading = false;

  planMsg = '';

  newPlan = '';
  
  historyMsg = '';

  model: any = {}
  cert:any ={
    status: false
  };

  paymentPlanInfo:any = {
    info:"carregando..."
  };

  invoices: any = []
  tries: any = []

  tx2 = "";
  validatorRes = [];

  res = "";

  paymentHistory :any = [];

  paymentHistoryColumns = [
    {name: 'ID Transação', sortingId: 'tid', loadingSort: false },
    {name: 'Criado', sortingId: 'date_created', loadingSort: false },
    {name: 'Atualização', sortingId: 'date_updated', loadingSort: false },
    {name: 'Método', sortingId: 'payment_method', loadingSort: false },
    {name: 'Status', sortingId: 'status', loadingSort: false },
    {name: 'Valor', sortingId: 'amount', loadingSort: false },
    {name: 'ASSINATURA', sortingId: 'subscription_id', loadingSort: false },
  ]

  invoiceColumns = [
    {name: 'Orçamento', sortingId: 'budget_id', loadingSort: false },
    {name: 'Cliente', sortingId: 'nomefatansia', loadingSort: false },
    {name: 'NFe', sortingId: 'invoice_number', loadingSort: false },
    {name: 'Valor', sortingId: 'vale', loadingSort: false },
    {name: 'Status', sortingId: 'status', loadingSort: false },
    {name: 'Data', sortingId: 'date', loadingSort: false },

  ]

  tryColumns = [
    {name: 'Orçamento', sortingId: 'budget_id', loadingSort: false },
    {name: 'Cliente', sortingId: 'razaosocial', loadingSort: false },
    {name: 'Tipo', sortingId: 'type', loadingSort: false },
    {name: 'Resposta API', sortingId: 'res', loadingSort: false },
    {name: 'Data', sortingId: 'date', loadingSort: false }
  ]

	id = null;
	user_id = null
  loading = false;

  showInvoices = false;

	constructor(
		private activatedRoute: ActivatedRoute,
    private clientsService: ClientsService,
		private invoicesService: InvoicesService,
		private formBuilder: FormBuilder,
		private formValidator: FormValidatorService,
		public helper: HelperService
	) {

	}
	ngOnInit() {
		this.user_id = this.activatedRoute.snapshot.paramMap.get('id');
		this.loading = true
		if (this.user_id) {
			this.clientsService.get(this.user_id).subscribe((data: ClientResultModel) => {

			})
		}
    this.tabClicked(1)



  }

  statusString(status){
    switch(parseInt(status)){
      case 0:
        return 'Cancelado'
      case 1:
      return 'Emitido'

      default:
     return status
    }
  }

  tabClicked(type) {
    console.log(type);

    if (type == 1) {
      this.getClient(this.user_id)
    this.getPaymentPlanInfo()
    this.getPlans()


    }
    if (type == 2) {
      this.getInvoices()
    }
    if (type == 3) {
      this.getTries()
    }
    if (type == 4) {
      this.getPaymentHistory()
    }
    if (type == 5) {
      this.getUserHistory()
    }


    this.type = type
  }

  tx2Format(tx2){
    return tx2.replace(/\n/g,'<br>') ;
  }

  changePlan(){
    if (confirm("Tem certeza que deseja alterar o plano do cliente?")) {

      this.clientsService.changeUserPlan(this.user_id,  this.newPlan).subscribe( (res: any) => {
        console.log(res)

        this.planMsg = "Plano alterado com sucesso. Novo plano: " + res.plan.name;

      })

    }
  }



	getClient(id) {
		this.clientsService.get(id).subscribe( (res: any) => {
			console.log(res)

      this.model = res

      this.loading = false
      this.getCertInfo()

      this.newPlan = res.plano;
		})
  }
  getUserHistory() {
    this.clientsService.getUserHistory(this.user_id).subscribe(res => {
      console.log(res);
      this.history = res
      this.loading = false

		})
  }
  getInvoices() {

		this.invoicesService.list(1,'',false,this.user_id).subscribe(res => {
      console.log(res);
      this.invoices = res
      this.loading = false

		})
  }

  getTries() {

		this.invoicesService.tries(1,'',false,this.user_id).subscribe(res => {
      console.log(res);
      this.tries = res
      this.loading = false

		})
  }


  getPaymentHistory() {

		this.clientsService.paymentHistory(this.user_id).subscribe(res => {
      this.paymentHistory = res
      this.loading = false

   })

  }

  getPaymentPlanInfo() {

		this.clientsService.paymentPlanInfo(this.user_id).subscribe((res : any) => {
        console.log(res);
        this.paymentPlanInfo = {
          Valor: 'R$ ' +  (res.plan.amount/100).toFixed(2),
          Plano_ID: res.plan.id,
          Plano_Nome: res.plan.name,
          Dias_gratis: res.plan.trial_days + ' dias',
          Intervalo: res.plan.days + ' dias',
          Metodos: res.plan.payment_methods[0] + ' | ' + res.plan.payment_methods[1],

        }
        // // this.loading = false

		})
  }



  getCertInfo() {

		this.clientsService.cert(this.user_id).subscribe(res => {

        this.cert = res
        this.loading = false

        this.cert.link1 = 'https://api.notanova.com.br/certificados/'+this.cert.cnpj+'/'+this.cert.cnpj+'.pfx';
        this.cert.link2 = 'http://legado.notanova.com.br/certificados/'+this.cert.cnpj+'/'+this.cert.cnpj+'.pfx';

		})
  }

  getPlans() {
    console.log('get plans parciero')
    this.clientsService.notanovaPlans().subscribe(res => {
      console.log(res)
      this.plans = res;
   })
  } 

  addToHistory(){
    this.clientsService.addToHistory(this.user_id,this.historyMsg).subscribe(res => {
      console.log(res)

      this.getUserHistory()
   
  })
  }

  validateTx2(id) {
    this.validatorLoading = true;
    this.clientsService.tx2Validator(id).subscribe(res => {
    
      this.validatorLoading = false;

      this.validatorRes = res;
 
      
  })
  }



}

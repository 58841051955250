import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { AccountService } from '../account.service'

@Injectable({
  providedIn: 'root'
})
export class RolesService implements CanActivate {

  constructor(private accountService: AccountService, private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  		var allowed = this.accountService.allowRoles(route.data['allowedRoles'])
  		console.log(allowed)
  		if(!allowed) {
  			this.router.navigate(['/forbidden'])	
  		}
  		return allowed
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-empty-estate',
  templateUrl: './empty-estate.component.html',
  styleUrls: ['./empty-estate.component.css']
})
export class EmptyEstateComponent implements OnInit {
	@Input() showBtn: Boolean = true;

	@Input() addLink: string; 

	@Input() title: string; 

	@Input() comentario: string; 
	constructor() { }

	ngOnInit() {
	}

}
